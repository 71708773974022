import { PropsSVG } from "./types";

function QuoteIcon({ width = 28, height = 24, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.71795 13.641C9.15385 13.641 10.2906 14.1197 11.1282 15.0769C12.0256 15.9744 12.4744 17.1709 12.4744 18.6667C12.4744 20.282 11.9359 21.5684 10.859 22.5256C9.84188 23.4231 8.4359 23.8718 6.64103 23.8718C4.54701 23.8718 2.90171 23.0641 1.70513 21.4487C0.568376 19.8333 0 17.5299 0 14.5385C0 11.8462 0.598291 9.36325 1.79487 7.08975C2.99145 4.75641 4.78632 2.42307 7.17949 0.0897424C7.23932 0.0299141 7.32906 0 7.44872 0C7.6282 0 7.77778 0.0897424 7.89744 0.269227C8.01709 0.448716 8.01709 0.598288 7.89744 0.717945C4.96581 3.7094 3.5 7.23931 3.5 11.3077C3.5 12.9829 3.79915 14.2094 4.39744 14.9872C4.9359 14.0897 6.04274 13.641 7.71795 13.641ZM23.2436 13.641C24.6795 13.641 25.8162 14.1197 26.6538 15.0769C27.5513 15.9744 28 17.1709 28 18.6667C28 20.282 27.4615 21.5684 26.3846 22.5256C25.3675 23.4231 23.9615 23.8718 22.1667 23.8718C20.0726 23.8718 18.4273 23.0641 17.2308 21.4487C16.094 19.8333 15.5256 17.5299 15.5256 14.5385C15.5256 11.8462 16.1239 9.36325 17.3205 7.08975C18.5171 4.75641 20.312 2.42307 22.7051 0.0897424C22.765 0.0299141 22.8547 0 22.9744 0C23.1538 0 23.3034 0.0897424 23.4231 0.269227C23.5427 0.448716 23.5427 0.598288 23.4231 0.717945C20.4915 3.7094 19.0256 7.23931 19.0256 11.3077C19.0256 12.9829 19.3248 14.2094 19.9231 14.9872C20.4615 14.0897 21.5684 13.641 23.2436 13.641Z"
        fill="#172C37"
      />
    </svg>
  );
}

export default QuoteIcon;
