import { PropsSVG } from "./types";

function WhatsAppContactIcon({ height = 18, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        data-fill="background"
        y="0.5"
        width="20"
        height="20"
        rx="10"
        fill="#172C37"
      />
      <path
        d="M3.93408 16.3168L4.79153 13.2031C4.26142 12.2892 3.98333 11.2542 3.98622 10.1961C3.98622 6.88067 6.6976 4.18506 10.026 4.18506C11.6424 4.18506 13.1603 4.81067 14.2987 5.94658C15.4401 7.08248 16.0687 8.59318 16.0658 10.199C16.0658 13.5145 13.3544 16.2101 10.0231 16.2101H10.0202C9.00923 16.2101 8.01563 15.9564 7.13212 15.4778L3.93408 16.3168ZM7.28565 14.3909L7.46814 14.5005C8.23869 14.956 9.1222 15.1953 10.0231 15.1981H10.026C12.7924 15.1981 15.0461 12.958 15.0461 10.2019C15.0461 8.86706 14.5247 7.61295 13.5774 6.66733C12.6302 5.7217 11.3672 5.20276 10.026 5.20276C7.25958 5.19988 5.00589 7.43997 5.00589 10.1961C5.00589 11.1389 5.26949 12.0586 5.77353 12.8543L5.8923 13.0445L5.38537 14.8868L7.28565 14.3909Z"
        fill="white"
      />
      <path
        fillRule="inherit"
        data-fill="background"
        d="M4.14551 16.1063L4.97398 13.0993C4.46126 12.22 4.19186 11.2196 4.19186 10.199C4.19475 6.99886 6.81054 4.39551 10.026 4.39551C11.5873 4.39551 13.0502 5.00094 14.151 6.09648C15.2517 7.19203 15.8572 8.65083 15.8572 10.2019C15.8572 13.402 13.2385 16.0054 10.026 16.0054H10.0231C9.04685 16.0054 8.08802 15.7603 7.23636 15.299L4.14551 16.1063Z"
        fill="#172C37"
      />
      <path
        d="M3.93408 16.3168L4.79153 13.2031C4.26142 12.2892 3.98333 11.2542 3.98622 10.1961C3.98622 6.88067 6.6976 4.18506 10.026 4.18506C11.6424 4.18506 13.1603 4.81067 14.2987 5.94658C15.4401 7.08248 16.0687 8.59318 16.0658 10.199C16.0658 13.5145 13.3544 16.2101 10.0231 16.2101H10.0202C9.00923 16.2101 8.01563 15.9564 7.13212 15.4778L3.93408 16.3168ZM7.28565 14.3909L7.46814 14.5005C8.23869 14.956 9.1222 15.1953 10.0231 15.1981H10.026C12.7924 15.1981 15.0461 12.958 15.0461 10.2019C15.0461 8.86706 14.5247 7.61295 13.5774 6.66733C12.6302 5.7217 11.3672 5.20276 10.026 5.20276C7.25958 5.19988 5.00589 7.43997 5.00589 10.1961C5.00589 11.1389 5.26949 12.0586 5.77353 12.8543L5.8923 13.0445L5.38537 14.8868L7.28565 14.3909Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51696 7.68203C8.40399 7.43121 8.28522 7.42544 8.17804 7.42256C8.09113 7.41968 7.98975 7.41968 7.88836 7.41968C7.78697 7.41968 7.62475 7.45716 7.48571 7.60707C7.34666 7.75699 6.9585 8.12025 6.9585 8.86118C6.9585 9.59923 7.50019 10.3142 7.57551 10.4151C7.65082 10.516 8.62124 12.0815 10.1536 12.684C11.4282 13.1857 11.6889 13.0848 11.9641 13.0588C12.2393 13.0329 12.8563 12.6956 12.9838 12.3439C13.1083 11.9921 13.1083 11.6923 13.0707 11.6289C13.033 11.5654 12.9316 11.528 12.781 11.453C12.6304 11.378 11.8888 11.0148 11.7498 10.9629C11.6107 10.9139 11.5093 10.8879 11.4108 11.0379C11.3094 11.1878 11.0198 11.5251 10.9329 11.626C10.846 11.7269 10.7562 11.7384 10.6055 11.6635C10.4549 11.5885 9.96824 11.4299 9.39178 10.9168C8.94278 10.5189 8.63862 10.0259 8.55172 9.876C8.46482 9.72608 8.54303 9.64536 8.61835 9.5704C8.68497 9.50409 8.76898 9.39454 8.84429 9.30805C8.91961 9.22156 8.94568 9.15813 8.99493 9.05723C9.04417 8.95632 9.021 8.86983 8.98334 8.79487C8.94568 8.7228 8.65021 7.97898 8.51696 7.68203Z"
        fill="white"
      />
    </svg>
  );
}

export default WhatsAppContactIcon;
